exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-editorial-js": () => import("./../../../src/pages/services/editorial.js" /* webpackChunkName: "component---src-pages-services-editorial-js" */),
  "component---src-pages-services-graphic-js": () => import("./../../../src/pages/services/graphic.js" /* webpackChunkName: "component---src-pages-services-graphic-js" */),
  "component---src-pages-services-marketing-js": () => import("./../../../src/pages/services/marketing.js" /* webpackChunkName: "component---src-pages-services-marketing-js" */),
  "component---src-pages-services-web-js": () => import("./../../../src/pages/services/web.js" /* webpackChunkName: "component---src-pages-services-web-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */)
}

